import React, { useState } from 'react';
import { Contract, JsonRpcProvider } from 'ethers';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import TheKoalitionABI from './TheKoalitionABI.json';
import './App.css'; // Make sure to import the CSS file

const CONTRACT_ADDRESS = "0x1316cD513dB5B9944101b652Ffe0137708998079";

function App() {
    const [walletAddresses, setWalletAddresses] = useState("");
    const [copied, setCopied] = useState(false);
    const [isLoading, setIsLoading] = useState(false); // State to track loading status

    const customRpcProvider = new JsonRpcProvider("https://connect.bit-rock.io", 7171);

    const getWalletAddresses = async () => {
        setIsLoading(true); // Start loading
        const contract = new Contract(CONTRACT_ADDRESS, TheKoalitionABI, customRpcProvider);
        const totalSupply = await contract.totalSupply();
        const addressSet = new Set();

        for (let i = 1; i <= totalSupply; i++) {
            try {
                const owner = await contract.ownerOf(i);
                addressSet.add(owner);
            } catch (error) {
                console.error("Failed to fetch owner for token ID:", i, error);
            }
        }

        const addresses = Array.from(addressSet).join(", ");
        setWalletAddresses(addresses);
        setIsLoading(false); // Finish loading
    };

    return (
        <div className="app-container">
            <h1>The Koalition Holders</h1>
            <div className="card">
                <textarea className="address-display" value={walletAddresses} readOnly />
                <button className="action-button" onClick={getWalletAddresses} disabled={isLoading}>
                    {isLoading ? 'Loading...' : 'Get Wallet Addresses'}
                </button>
                <CopyToClipboard text={walletAddresses} onCopy={() => setCopied(true)}>
                    <button className="copy-button" disabled={copied || !walletAddresses}>
                        Copy to Clipboard
                    </button>
                </CopyToClipboard>
                {copied ? <span className="copy-confirm">Copied.</span> : null}
            </div>
        </div>
    );
}

export default App;
